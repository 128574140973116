<template>
  <v-row class="pa-2" id="dashboard">
    <Overlay :loading="loading" />

    <v-row class="pa-4">
      <v-col cols="12" md="3" >
        <v-row no-gutters>
          <span class="text-h4">
            <!-- Olá, {{ currentUser.name || "Não definido" }} -->
            Alertas
          </span>
        </v-row>
        <v-row no-gutters>
          <span class="caption font-weight-light pt-0 pb-0">
            <!-- {{currentDate}} -->
            Ordenados por ordem de chegada e prioridade
          </span>
        </v-row>
      </v-col>
      <v-col cols="12" md="9">
        <PatientStatus />
      </v-col>
    </v-row>

    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-row>
                <v-col cols="12">
                  <v-sheet outlined color="grey" rounded>
                    <v-card width="100%" height="75vh" color="#F0F0F0" class="vCard elevation-0" outlined>
                      <v-app-bar color="#BC2E2E" id="priority" class="d-flex justify-center elevation-0">
                        <v-toolbar-title class="white--text">
                          <span id="title">Parâmetros Acima do Normal</span>
                        </v-toolbar-title>
                      </v-app-bar>
                      <v-container fluid>
                        <v-row class="cols-12 sm-12 md-6 xl-4 mt-2 pb-0 pa-2" width="100%" dense
                          v-for="item in filterByRisk('high')" :key="item.user_id" no-gutters>
                          <v-card class="cardList" @click="onPressAlert(item.user_id, 'alert')" outlined>
                            <v-container>
                              <v-row>
                                <v-col cols="6" md="6" sm="6">
                                  <h2 class="pName">
                                    {{ formatName(item.patient.name) }}
                                  </h2>
                                </v-col>
                                <v-col cols="5" md="5" sm="5" class="
                                      parameter
                                      d-flex
                                      justify-center
                                      pa-0
                                      mb-0
                                      max-vh-1
                                    ">
                                  <h2 class="white--text mt-1">
                                    {{ item.value }}
                                  </h2>
                                </v-col>
                                <v-col cols="1" md="1" sm="1" class="d-flex justify-center pa-0 mb-0">
                                  <!-- <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="menu mt-2" color="black" icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item-content>
                                        <v-list-item @click="
                                          $router.push({
                                            name: 'AttendancePatientCreate',
                                            params: {
                                              patient_id: item.user_id,
                                              alert_id: item.id,
                                            },
                                          })
                                        ">
                                          <v-icon left>mdi-plus-circle-outline</v-icon>
                                          <v-list-item-title>Novo atendimento</v-list-item-title>
                                        </v-list-item>
                                      </v-list-item-content>
                                    </v-list>
                                  </v-menu> -->
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-row>
                <v-col cols="12">
                  <v-sheet outlined color="grey" rounded>
                    <v-card width="100%" height="75vh" color="#F0F0F0" class="vCard elevation-0" outlined>
                      <v-app-bar color="#BC2E2E" id="priority" class="d-flex justify-center elevation-0">
                        <v-toolbar-title class="white--text">
                          <span id="title">Parâmetros Abaixo do Normal</span>
                        </v-toolbar-title>
                      </v-app-bar>
                      <v-container fluid>
                        <v-row class="cols-12 sm-12 md-6 xl-4 mt-2 pb-0 pa-2" width="100%" dense
                          v-for="item in filterByRisk('low')" :key="item.user_id" no-gutters>
                          <v-card class="cardList" @click="onPressAlert(item.user_id, 'alert')" outlined>
                            <v-container>
                              <v-row class="tas">
                                <v-col cols="6" md="6" sm="6">
                                  <h2 class="pName">
                                    {{ formatName(item.patient.name) }}
                                  </h2>
                                </v-col>
                                <v-col cols="5" md="5" sm="5" class="
                                      parameter
                                      d-flex
                                      justify-center
                                      pa-0
                                      mb-0
                                      max-vh-1
                                    ">
                                  <h2 class="white--text mt-1">
                                    {{ item.value }}
                                  </h2>
                                </v-col>
                                <v-col cols="1" md="1" sm="1" class="d-flex justify-center pa-0 mb-0">
                                  <!-- <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="menu mt-2" color="black" icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item-content>
                                        <v-list-item @click="
                                          $router.push({
                                            name: 'AttendancePatientCreate',
                                            params: {
                                              patient_id: item.user_id,
                                              alert_id: item.id,
                                            },
                                          })
                                        ">
                                          <v-icon left>mdi-plus-circle-outline</v-icon>
                                          <v-list-item-title>Novo atendimento</v-list-item-title>
                                        </v-list-item>
                                      </v-list-item-content>
                                    </v-list>
                                  </v-menu> -->
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-row>
                <v-col cols="12">
                  <v-sheet outlined color="grey" rounded>
                    <v-card width="100%" height="75vh" color="#F0F0F0" class="vCard elevation-0" outlined>
                      <v-app-bar color="#D6691A" id="priority" class="d-flex justify-center elevation-0">
                        <v-toolbar-title class="white--text">
                          <span id="title">Alertas Recorrentes</span>
                        </v-toolbar-title>
                      </v-app-bar>
                      <v-container fluid>
                        <v-row class="cols-12 sm-12 md-6 xl-4 mt-2 pb-0 pa-2" width="100%" dense
                          v-for="item in filterByRisk('recurrent')" :key="item.user_id" no-gutters>
                          <v-card class="cardList" @click="onPressAlert(item.user_id, 'alert')" outlined>
                            <v-container>
                              <v-row>
                                <v-col cols="6" md="6" sm="6">
                                  <h2 class="pName">
                                    {{ formatName(item.patient.name) }}
                                  </h2>
                                </v-col>
                                <v-col cols="5" md="5" sm="5" class="
                                      parameter
                                      d-flex
                                      justify-center
                                      pa-0
                                      mb-0
                                      max-vh-1
                                    ">
                                  <h2 class="white--text pa-2">
                                    {{ item.value }}
                                  </h2>
                                </v-col>
                                <v-col cols="1" md="1" sm="1" class="d-flex justify-center pa-0 mb-0">
                                  <!-- <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="menu mt-2" color="black" icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item-content>
                                        <v-list-item @click="
                                          $router.push({
                                            name: 'AttendancePatientCreate',
                                            params: {
                                              patient_id: item.user_id,
                                              alert_id: item.id,
                                            },
                                          })
                                        ">
                                          <v-icon left>mdi-plus-circle-outline</v-icon>
                                          <v-list-item-title>Novo atendimento</v-list-item-title>
                                        </v-list-item>
                                      </v-list-item-content>
                                    </v-list>
                                  </v-menu> -->
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Overlay from "../../components/Overlay/Overlay.vue";
import PatientStatus from "../../components/Patients/PatientsStatus.vue";
import moment from "moment";
// import AreaChart from "../../components/Charts/LineChart.vue";
// import Excel from "../../components/Excel/Excel.vue";

export default {
  components: {
    Overlay,
    PatientStatus,
    // AreaChart,
    // Excel,
  },
  data: () => ({
    fullscreenMode: false,
    alert: {
      risk: "",
      status: "",
      color: "".toString,
    },
    allPatients: [],
    patients: [],
    loading: false,
    highRiskAlertMedia: 0,
    lowRiskAlertMedia: 0,
    currentDate: new Date().toLocaleDateString("pt-br", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    activePatients: 0,
    inactivePatients: 0,
    dashboardPatients: [
      {
        id: 1,
        title: "Pacientes sendo acompanhados",
        value: "00",
        color: "#1C3B7B",
      },
      {
        id: 1,
        title: "Pacientes com exames",
        value: "00",
        color: "#1C3B7B",
      },
    ],
    optionLineChart: {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["Acompanhados", "Alterados", "Exames"],
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Dom", "Seg", "Ter", "Quarta", "Qui", "Sex", "Sáb"],
      },
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "Acompanhados",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [0, 80, 67, 35, 150, 124, 25, 37],
        },
        {
          name: "Alterados",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [75, 80, 120, 11, 5, 24, 193, 72],
        },
        {
          name: "Exames",
          type: "line",
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: [120, 90, 45, 186, 34, 175, 23, 9],
        },
      ],
    },
    json_fields_alert: {
      Id: "id",
      Título: {
        field: "title",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Valor: "value",
      Risco: "risk",
      Paciente: {
        field: "patient.name",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
    json_fields_patient: {
      Nome: {
        field: "name",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Idade: "age",
      PA: "PA",
      FC: "FC",
      Temperatura: "temperature",
      Saturação: "saturation",
      "Sono total": "sleep",
      Dispositivo: "status",
    },
    initialDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    finalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    initialDateAlert: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    finalDateAlert: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
  }),
  mounted() {
    this.updateEveryXminutes(0.25);
    if (this.currentUser && this.currentUser.id) {
      this.getPatients();
      this.allPatients = this.patients;
      this.dashboardPatients[0].value =
        this.patients.length < 10
          ? "0" + this.patients.length
          : this.patients.length;
    }
  },
  methods: {
    ...mapActions("patients", ["getPatients"]),
    ...mapActions("alerts", ["updateAlerts"]),
    formatName(fullName) {
      const nameArray = fullName.split(' ');
      if (nameArray.length === 1) return fullName
      const firstName = nameArray[0];
      const secondName = nameArray[1][0] + '.';
      const thirdName = nameArray[nameArray.length - 1];
      return `${firstName} ${secondName} ${thirdName}`;
    },
    fullscrenMode() {
      var element = document.querySelector("#dashboard");
      this.fullscreenMode = !this.fullscreenMode;

      if (this.fullscreenMode) {
        element.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).locale("pt-BR").format("L");
      }
    },
    onChangeAlertTemplate(alert) {
      const types = {
        temperature_dropped: {
          title: "Temperatura reduzida",
          color: "#ED7F30",
          colorC: "#D6691A",
          value: alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        temperature_rose: {
          title: "Temperatura elevada",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.real_temperature + "°C",
          risk: alert ? alert.risk : "none",
        },
        beats_dropped: {
          title: "Batimentos reduzidos",
          color: "#ED7F30",
          colorC: "#D6691A",
          value: alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        beats_rose: {
          title: "Batimentos elevados",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.bpm,
          risk: alert ? alert.risk : "none",
        },
        dbp_dropped: {
          title: "DBP reduzido",
          color: "#ED7F30",
          colorC: "#D6691A",
          value: alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        dbp_rose: {
          title: "DBP elevado",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_dropped: {
          title: "SBP reduzido",
          color: "#ED7F30",
          colorC: "#D6691A",
          value: alert.alertable.sbp,
          risk: alert ? alert.risk : "none",
        },
        sbp_rose: {
          title: "SBP elevado",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.dbp,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_dropped: {
          title: "Oxigenação reduzida",
          color: "#CF4444",
          colorC: "#C23535",
          value: alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        blood_oxygen_rose: {
          title: "Oxigenação elevada",
          color: "#ED7F30",
          colorC: "#D6691A",
          value: alert.alertable.blood_oxygen,
          risk: alert ? alert.risk : "none",
        },
        total_sleep_dropped: {
          title: "Horas de sono reduzida",
          color: "#ED7F30",
          colorC: "#D6691A",
          value:
            (alert.alertable.deep_sleep_total +
              alert.alertable.light_sleep_total) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
        total_sleep_rose: {
          title: "Horas de sono elevada",
          color: "#CF4444",
          value:
            (alert.alertable.deep_sleep_total +
              alert.alertable.light_sleep_total) /
            60,
          risk: alert.alert ? alert.risk : "none",
        },
        fence_out_exam: {
          title: "Localização fora ",
          color: "#CF4444",
          value: alert.alertable.fence_out_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        fence_in_exam: {
          title: "Area limite quebrada",
          color: "#CF4444",
          value: alert.alertable.fence_in_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        sos_exam: {
          title: "Botão SoS acionado",
          color: "#CF4444",
          value: alert.alertable.sos_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        falldown_exam: {
          title: "Alerta de Queda",
          color: "#CF4444",
          value: alert.alertable.falldown_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        low_battery_exam: {
          title: "Dispositivo descarregando",
          color: "#CF4444",
          value: alert.alertable.low_battery_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        wifi_in_exam: {
          title: "Dentro da cerca de Wifi",
          color: "#CF4444",
          value: alert.alertable.wifi_in_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        wifi_out_exam: {
          title: "Fora da cerca de Wifi",
          color: "#CF4444",
          value: alert.alertable.wifi_out_exam,
          risk: alert.alert ? alert.risk : "none",
        },
        device_offline_exam: {
          title: "Dispositivo Offline",
          color: "#CF4444",
          value: alert.alertable.device_offline_exam,
          risk: alert.alert ? alert.risk : "none",
        },
      };

      return (
        types[alert ? alert.status : "nothing"] || {
          title: "Nenhuma notificação",
          color: "#256DF4",
          risk: "none",
        }
      );
    },
    async showFilteredPatients(event) {
      try {
        this.loading = true;

        const params = Object.assign(
          {
            doctor_id: this.currentUser.id,
            report_type: event.value.type,
            risk: event.value.risk,
          },
          { [event.value.name]: event.value.value }
        );

        const response = await this.$axios("reports", {
          params: params,
        });

        this.allPatients = this.findPatients(response.data);
        this.openFilter = false;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar filtrar os pacientes.",
        });
      }
    },
    onPressAlert(id, type) {
      if (type === "alert") {
        this.$router.push({
          name: "Patient",
          params: {
            id: id,
            type: type,
          },
        });

        return;
      }

      this.$router.push({ path: "/patient/" + id });
    },
    onChangePage(path) {
      this.$router.push({
        path: path,
      });
    },
    updateEveryXminutes(minutes) {
      setInterval(() => {
        this.getPatients();
      }, minutes * 30000);
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
      alerts: (state) => state.alerts.alerts,
    }),
    ...mapGetters({
      disconnectedPatients: "patients/disconnectedPatients",
      findPatient: "patients/findPatient",
      findPatients: "patients/findPatients",
      filterByRisk: "alerts/filterByRisk",
    }),
  },
  watch: {
    "$store.state.patients.patients"(val) {
      this.allAlerts = val;
    },
    "$store.state.alerts.alerts"(val) {
      this.allAlerts = val;
    },
  },
};
</script>

<style scopped>
@import "../../assets/Style/Dashboard.css";
</style>
