<template>
  <div>
    <v-row class="mt-1" align="center" justify="end">
      <!-- Registered -->
      
        <v-card
          height="40"
          width="187"
          color="#F0F0F0"
          class="elevation-2 rounded-3 mr-6"
          elevation="1"
          outlined
          @click="
            $router.push({ name: 'ListPatient', params: { role_id: 'Ativo' } })
          "
        >
          <v-card-text class="pt-0 pb-0 mt-2">
            <v-row no-gutters>
              <v-spacer />
              <v-col cols="auto" sm="auto" md="auto" class="mr-2">
                {{ patients.length }}
              </v-col>
              <v-col cols="auto" sm="auto" md="auto" class="text-center ml-0">
                <strong class="registred" v-if="patients.length == 1">
                  cadastro ativo
                </strong>
                <strong class="registred" v-else> cadastros ativos </strong>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
      

      <!-- Conected -->
     
        <v-card
          elevation="1"
          outlined
          height="40"
          width="187"
          color="#F0F0F0"
          class="elevation-2 rounded-3 mr-6"
          @click="
            $router.push({ name: 'ListPatient', params: { status: 'Ligado' } })
          "
        >
          <v-card-text class="pt-0 pb-0 mt-2">
            <v-row no-gutters>
              <v-spacer />
              <v-col cols="auto" sm="auto" md="auto" class="mr-2">
                {{ connectedPatients.length }}
              </v-col>
              <v-col cols="auto" sm="auto" md="auto" class="text-center ml-0">
                <strong class="conected" v-if="connectedPatients.length == 1">
                  conectado
                </strong>
                <strong class="conected" v-else> conectados </strong>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
     
      <!-- Desconected -->
     
        <v-card
          elevation="1"
          outlined
          height="40"
          width="187"
          color="#F0F0F0"
          class="elevation-2 rounded-3 mr-6"
          @click="
            $router.push({
              name: 'ListPatient',
              params: { status: 'Desligado' },
            })
          "
        >
          <v-card-text class="pt-0 pb-0 mt-2">
            <v-row no-gutters>
              <v-spacer />
              <v-col cols="auto" sm="auto" md="auto" class="mr-2">
                {{ disconnectedPatients.length }}
              </v-col>
              <v-col cols="auto" sm="auto" md="auto" class="text-center ml-0">
                <strong
                  class="desconected"
                  v-if="disconnectedPatients.length == 1"
                >
                  desconectado
                </strong>
                <strong class="desconected" v-else> desconectados </strong>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
     

      <!-- Fullscreen btn -->
      
        <v-card
          height="40"
          width="40"
          color="#F0F0F0"
          class="elevation-2 rounded-3 mr-6"
          elevation="1"
          outlined
          @click="fullscrenMode()"
        >
          <v-card-text class="pt-0 pb-0 mt-2">
            <v-row no-gutters>
              <v-spacer />
              <v-col
                cols="auto"
                sm="auto"
                md="auto"
                class="d-flex text-center justify-center"
              >
                <v-icon icon class="">mdi-fullscreen-exit</v-icon>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
      
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "patientstatus",
  props: {
    content: {
      type: Object,
    },
  },
  data: () => ({}),
  methods: {
    fullscrenMode() {
      this.$parent.fullscrenMode();
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
      alerts: (state) => state.alerts.alerts,
      patients: (state) => state.patients.patients,
    }),
    ...mapGetters({
      connectedPatients: "patients/connectedPatients",
      disconnectedPatients: "patients/disconnectedPatients",
      findPatient: "patients/findPatient",
      findPatients: "patients/findPatients",
    }),
  },
};
</script>
<style scoped>
.registred {
  color: #1c3b7b;
}
.conected {
  color: #45a32e;
}
.desconected {
  color: #cf4444;
}
</style>
